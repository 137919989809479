<template>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'ball'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="005-ball"><path data-name="Path 359" d="M13.22 9.47a7.5 7.5 0 109.32 9.32l1.77-6A6.85 6.85 0 0126 10a6.52 6.52 0 013.91-1.87 15.47 15.47 0 00-6-6A6.5 6.5 0 0122 6a6.88 6.88 0 01-2.82 1.7z"/><path data-name="Path 360" d="M30.82 10a4.65 4.65 0 00-3.5 1.35 4.94 4.94 0 00-1.23 2l-1.76 6A9.37 9.37 0 1112.69 7.67l6-1.75a5 5 0 002-1.23A4.75 4.75 0 0022 1.19 16 16 0 1030.81 10z"/></g></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'brush'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="006-pet-brush"><path data-name="Path 349" d="M15.72 8.38l5.63-3.25.09-.35a.86.86 0 00-.62-1.07l-.35-.1-5.62 3.25z"/><path data-name="Path 350" d="M19.23 14.45l5.62-3.25.1-.35a.87.87 0 00-.62-1.07l-.33-.1-5.63 3.25z"/><path data-name="Path 351" d="M17.47 11.41l5.63-3.24.09-.36a.87.87 0 00-.62-1.07l-.35-.09L16.6 9.9z"/><path data-name="Path 352" d="M21.86 19l3.79-2.19a.9.9 0 00.41-.53l.64-2.4a.87.87 0 00-.62-1.07l-.35-.09L20.1 16z"/><path data-name="Path 353" d="M9.18 4.06l1.52-.88 9.64 16.7-1.52.87z"/><path data-name="Path 354" d="M5.51 8.21l13.2 22.87a1.75 1.75 0 003.09-1.66l-.06-.1L7.66 4.94l-1.51.87a1.75 1.75 0 00-.64 2.4z"/><path data-name="Path 355" d="M19.69 1.74a.88.88 0 00-.62-1.07L16.68 0a.87.87 0 00-.68.12l-3.78 2.19 1.75 3 5.62-3.25z"/></g></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'file'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path data-name="Path 301" d="M26.43 8.93l-5.68-5.69a4.52 4.52 0 00-2.41-1.19v9.28h9.28a4.42 4.42 0 00-1.19-2.4z"/><path data-name="Path 302" d="M17.75 13.67A1.74 1.74 0 0116 11.93V2H6.09a1.68 1.68 0 00-1.25.51 1.7 1.7 0 00-.51 1.24v24.5A1.74 1.74 0 006.06 30h19.86a1.74 1.74 0 001.75-1.73v-14.6z"/></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'food'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path data-name="Path 334" d="M28.62 20.32H3.38L0 28.45h32z"/><path data-name="Rectangle 72" d="M4.1 15.32h23.79v3.13H4.1z"/><path data-name="Path 335" d="M25 6.73a3.07 3.07 0 00-1.43-.82 3.17 3.17 0 10-6 2l-5.53 5.52h8l1.52-1.52A3.18 3.18 0 0025 6.73z"/></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'gender'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M28 1.48v-.14a.13.13 0 000-.06v-.22a.41.41 0 000-.08V.79l-.27-.07V.66l-.08-.07a1.52 1.52 0 00-.25-.26l-.11-.08h-.19L27 .11V.09h-5.24a1.49 1.49 0 000 3h1.11l-2.25 2.13a10.36 10.36 0 10-7.7 18.54v1.93h-1.84a1.49 1.49 0 000 3h1.84v1.84a1.49 1.49 0 003 0v-1.86h1.83a1.49 1.49 0 000-3H15.9v-1.91a10.34 10.34 0 006.82-16.42L25 5.09v1.1a1.49 1.49 0 103 0V1.48zM14.41 20.89a7.38 7.38 0 117.38-7.38 7.39 7.39 0 01-7.38 7.38z"/></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'heartbeat'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path data-name="Path 356" d="M9.29 14.8a.94.94 0 01.93.94v3.49a.92.92 0 001.84 0v-5.55a2.79 2.79 0 015.58 0v2.6a.92.92 0 001.83 0v-3.84a2.79 2.79 0 015.53-.21h6.88a9 9 0 00.12-1.16c0-5.44-3.69-9.85-8.24-9.85A8.47 8.47 0 0016 7.79a8.47 8.47 0 00-7.76-6.57C3.69 1.22 0 5.63 0 11.07a11 11 0 00.68 3.73z"/><path data-name="Path 357" d="M24.12 14.1a.94.94 0 01-.94-.94v-.69a.93.93 0 00-.87-.94.9.9 0 00-1 .87v3.84a2.83 2.83 0 01-2.83 2.84 2.79 2.79 0 01-2.74-2.79v-2.57a1 1 0 00-.87-.94.92.92 0 00-1 .87v5.53A2.82 2.82 0 0111.11 22a2.8 2.8 0 01-2.75-2.79v-2.53H1.49C4.71 23 12.29 28.61 15 30.47a1.75 1.75 0 002 0c3.07-2.12 12.33-9 14.53-16.36h-7.4z"/></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'info'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path data-name="Path 42" d="M16 2a14 14 0 1014 14A14 14 0 0016 2zm2.14 21.64a2.14 2.14 0 11-4.27 0v-9.29a2.14 2.14 0 114.27 0zM16 10.46a2.34 2.34 0 112.34-2.34A2.34 2.34 0 0116 10.46z"/></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'lock'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M25 12h-1V8A8 8 0 108 8v4H7a3 3 0 00-3 3v14a3 3 0 003 3h18a3 3 0 003-3V15a3 3 0 00-3-3zM10.67 8a5.33 5.33 0 0110.66-.22V12H10.67zm6.66 14.3v3a1.33 1.33 0 11-2.66.11V22.3a2.67 2.67 0 113.64-1 2.71 2.71 0 01-.98 1z"/></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'logout'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path data-name="Path 40" d="M13.06 27.1a.5.5 0 010-.27c0-.08 0-.14-.14-.19s-.17-.08-.17-.11-.08-.05-.22-.06H5.88a3.13 3.13 0 01-2.31-1 3.15 3.15 0 01-1-2.3V8.82a3.15 3.15 0 011-2.3 3.1 3.1 0 012.31-1h6.53a.59.59 0 00.41-.13A.65.65 0 0013 5a3.93 3.93 0 00.06-.48 4.86 4.86 0 000-.54v-.41a.67.67 0 00-.64-.57H5.88a5.65 5.65 0 00-4.16 1.67A5.69 5.69 0 000 8.82v14.37a5.65 5.65 0 001.73 4.14 5.62 5.62 0 004.15 1.72h6.53a.64.64 0 00.42-.13.7.7 0 00.2-.4 3.93 3.93 0 00.06-.48 4.86 4.86 0 000-.54c-.02-.21-.03-.34-.03-.4z"/><path data-name="Path 41" d="M31.61 15.09L20.51 4a1.31 1.31 0 00-1.85 0 1.36 1.36 0 00-.38.91v5.88H9.14a1.32 1.32 0 00-1.3 1.31v7.84a1.32 1.32 0 001.3 1.3h9.15v5.86a1.29 1.29 0 002.22.92l11.1-11.1a1.28 1.28 0 000-1.81z"/></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'notification'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path data-name="Path 32" d="M14.67 32a5 5 0 004.9-4h-9.8a5 5 0 004.9 4z"/><path data-name="Path 33" d="M24 16a9.34 9.34 0 01-9.33-9.33 9.13 9.13 0 01.89-3.95 8.7 8.7 0 00-.89 0A9.34 9.34 0 005.33 12v3.71a8.92 8.92 0 01-3.18 6.85A2.31 2.31 0 001.42 25a2.42 2.42 0 002.39 1.71h21.7a2.45 2.45 0 002.49-1.9 2.35 2.35 0 00-.8-2.29A8.88 8.88 0 0124 16z"/><path data-name="Path 34" d="M30.67 6.67A6.67 6.67 0 1124 0a6.67 6.67 0 016.67 6.67z"/></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'pee'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="Group 309"><path data-name="Path 337" d="M15.83 14.12l8.92 3.72 3.71-1.07a1.58 1.58 0 11.87 3l-3.86 1.12-2.39 5.17a1.55 1.55 0 01-1.26 1.07l-1.82.31 2 1a1.58 1.58 0 01-1.43 2.81L14.09 28a1.59 1.59 0 01-.69-2.12 1.56 1.56 0 011.17-.85l1.21-.18-6.11-2.49v6.94a1.58 1.58 0 01-1.58 1.58 1.58 1.58 0 01-1.58-1.58V15.18z"/><path data-name="Path 338" d="M.12 7.34C.64 5.72 5 6.08 5.41 5.91 6.79 5.4 7.57 4.44 9 4.11A6.36 6.36 0 019.76 4a.27.27 0 00.24-.25l.44-1.43a2.4 2.4 0 011.71-1.7c.34-.08 1.91-.17 1.57 2.23a7.58 7.58 0 00-.05 1.58 49.81 49.81 0 001 5.67l.52 2.56-8.63 1.07s-.73-2-.95-2h-.93a5.61 5.61 0 01-2.58-.56A3.16 3.16 0 01.12 7.34z"/><path data-name="Path 339" d="M27.36 27.42a1.34 1.34 0 011.42-.22v-.05a1.68 1.68 0 01.22-.25l.19-.15a1.09 1.09 0 01.31-.14h.11v.11a.66.66 0 00-.06.27v.17a1.06 1.06 0 000 .19 2.48 2.48 0 00.17.37l.17.26.11.02a6.76 6.76 0 01.89.33 2.52 2.52 0 01.59.39 1.4 1.4 0 01.43.63 1.13 1.13 0 01-.11.93.94.94 0 01-.14.19 1.32 1.32 0 01-.46.32 1.7 1.7 0 01-.32.1 2.07 2.07 0 01-.5.06c-3.63.14-3.77-.36-4.19-.79a1.61 1.61 0 011.12-2.73z"/><path data-name="Path 340" d="M28 24.89a.33.33 0 01.42.21c.07.21.1.34.1.34a.34.34 0 01-.18.44.35.35 0 01-.45-.18.43.43 0 010-.11s0-.12-.09-.29a.34.34 0 01.2-.41z"/><path data-name="Path 341" d="M25.76 21.91a4 4 0 01.64.35.34.34 0 01.07.47.36.36 0 01-.26.14.33.33 0 01-.19-.06 3 3 0 00-.53-.29.34.34 0 01-.25-.41.33.33 0 01.4-.25.22.22 0 01.12.05z"/><path data-name="Path 342" d="M27.56 24.33a.35.35 0 01-.29-.16 3.54 3.54 0 00-.35-.51.34.34 0 01.52-.41 6 6 0 01.41.58.34.34 0 01-.12.46.33.33 0 01-.17.04z"/></g></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'petting'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path data-name="Path 332" d="M31.14 25.8l-1.59-.14a7.31 7.31 0 01-5.37-3.15 3.39 3.39 0 01-2.61 1.24h-.14a3.37 3.37 0 01-3.11-2.37l-.61-1.94a.94.94 0 011.76-.64v.08l.61 1.93a1.49 1.49 0 001.39 1.06h.06a1.48 1.48 0 001.4-.94l1-2.52a3.74 3.74 0 00-3.47-5.16h-3.68a3.41 3.41 0 00-2.4 1l-2.72 2.63H9.81a.94.94 0 00-.93.94v3a3.12 3.12 0 002.19 3 3.27 3.27 0 012.27 3.11v.05a3 3 0 01-.15.94 11.22 11.22 0 00-.57 3.08.93.93 0 00.89 1h17.55a.94.94 0 00.94-.94v-4.32a.94.94 0 00-.86-.94z"/><path data-name="Path 333" d="M8.44 5.62a.94.94 0 01.94-.93h3.9a4.22 4.22 0 014 2.79l3.41 1.15a2.16 2.16 0 001.61-4L14.6 1a10.94 10.94 0 00-10.27.53L.46 3.88a.94.94 0 00-.46.81v7.5a.94.94 0 00.94.93h3a7.26 7.26 0 004.9-1.87h4.43a2.35 2.35 0 00.09-4.69H9.38a.94.94 0 01-.94-.94z"/></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'play'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="Group 311"><path data-name="Path 361" d="M2 10.06c6.51 5.27 10.31 8 12.65 9.81a6.67 6.67 0 011.42-2.14.72.72 0 011.05 1 5.44 5.44 0 00-1.39 2.53 12.4 12.4 0 00-.05 5.32l-1.34 3.7a1.35 1.35 0 002.53.93l1.45-4A14.9 14.9 0 0022 23.55a6 6 0 00.9-2.42 8.4 8.4 0 003.83 0 11.1 11.1 0 003.91-1.78.5.5 0 00-.51-.84 9.57 9.57 0 01-3.64.94 6.22 6.22 0 01-3.58-.83 4.14 4.14 0 00-.78-1.75L15.35 8s.14-1 .28-2.12c-1.44.24-3.3.3-3.94-1s.64-2.77 1.07-3.23A10.54 10.54 0 0114 .48a3.29 3.29 0 00-3.4 0A10.48 10.48 0 008.83.12a1 1 0 00-1 .9l-.11 1.27a2.34 2.34 0 001.46 2.4 1.62 1.62 0 01.89 2.11v.1c-.6 1.24-1.43 3-2 4.54L3.4 7.7a1.36 1.36 0 00-1.89.2 1.63 1.63 0 00-.25.47C1 9.12 2 10.06 2 10.06z"/><path data-name="Path 363" d="M16.59 2.35c-.42-.85-1.24-1.3-1.68-1.08s-2.78 2.23-2.36 3.08 3.45.2 3.88 0 .57-1.15.16-2z"/></g></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'shield'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path data-name="Path 300" d="M29.59 8.68v-1a3.4 3.4 0 00-3.2-3.32 13.21 13.21 0 01-9-3.78 2.05 2.05 0 00-2.79 0 13.14 13.14 0 01-9 3.78 3.38 3.38 0 00-3.16 3.31v1.06a34.46 34.46 0 001.4 12.65 16.12 16.12 0 003.86 6 20.29 20.29 0 007.57 4.48c.1 0 .21.06.31.09a2.72 2.72 0 00.9 0 2.8 2.8 0 00.31-.09 20.24 20.24 0 007.55-4.47 16 16 0 003.87-6 34.74 34.74 0 001.38-12.71zm-5.88 4l-8.81 8.71a1.36 1.36 0 01-1.9 0l-4.68-4.22a1.41 1.41 0 01-.1-1.94 1.37 1.37 0 011.93-.07l3.71 3.38 7.88-7.88a1.41 1.41 0 012 0 1.39 1.39 0 010 2z"/></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'stopwatch'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="09-timer"><path data-name="Path 330" d="M25.69 9.52l1.93-1.9-1.89-1.89-2.05 2a13.33 13.33 0 00-6.34-2.33V2.67H20V0h-8v2.67h2.67V5.4a13.33 13.33 0 00-6.34 2.37l-2-2L4.4 7.62l1.91 1.9a13.33 13.33 0 1019.38 0zM16 29.34a10.67 10.67 0 1110.67-10.67A10.67 10.67 0 0116 29.34z"/><path data-name="Path 331" d="M16 10.67v8H8a8 8 0 108-8z"/></g></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'train'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path data-name="Path 362" d="M26.42 19.06a1.14 1.14 0 00-1.31-.93H25l-2.08.5-3.57.85a9.59 9.59 0 00-3-3.46 7.22 7.22 0 00-.93-.57 8.76 8.76 0 005-2.24 9.39 9.39 0 002.91-5.83 1.12 1.12 0 00-1-1.25h-.09l-5.44-.2a4.27 4.27 0 00-3.66-2.09h-.42L11.46 1A1.13 1.13 0 0010 .44a1.15 1.15 0 00-.57.6l-.79 1.82L6.92.47A1.14 1.14 0 005.33.22a1.12 1.12 0 00-.46.91v28.06A18.61 18.61 0 0020.61 31q.18-1.26.27-2.43a22 22 0 00-.15-4.86l2.61-1.12.79 1.69a1.14 1.14 0 001.48.56l.84-.37a1.14 1.14 0 00.67-1.22z"/></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'user'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="Group 258"><path data-name="Path 298" d="M16 0a7.81 7.81 0 11-7.81 7.81A7.81 7.81 0 0116 0z"/><path data-name="Path 299" d="M16 32a17.37 17.37 0 01-11.29-4.16 3.37 3.37 0 01-1.17-2.53 7.88 7.88 0 017.88-7.87h9.13a7.86 7.86 0 017.9 7.83 3.29 3.29 0 01-1.16 2.53A17.32 17.32 0 0116 32z"/></g></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'walk'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="004-dog-puppy-and-his-owner-looking-to-opposite-directions"><path data-name="Path 343" d="M17.39 16.4l-8.62-.24L6 13.92a1.39 1.39 0 00-1.73 2.17l2.84 2.28-.74 5.4-4.47 4.7a1.39 1.39 0 002 1.91l3.46-3.66L5.82 30a1.4 1.4 0 00.66 1.85 1.41 1.41 0 001.86-.66l3-6.23 6.78.05-2.13 5a1.39 1.39 0 002.54 1.12l2.8-6.64 1.91-4.13z"/><path data-name="Path 344" d="M27.42 13.41c-.73-.85-.88-1.82-1.73-2.54a5.19 5.19 0 00-.46-.36.22.22 0 01-.1-.26c.1-.4.19-.8.28-1.2A1.84 1.84 0 0025 7.21c-.2-.18-1.24-.77-2 1.13a6.32 6.32 0 01-.6 1.19 49 49 0 01-3 3.9L18 15.16l5.48 3.68s1.32-1.26 1.47-1.19l.63.32a4.1 4.1 0 002 .45 2.89 2.89 0 002.91-2.18c.27-1.37-2.83-2.56-3.07-2.83z"/><path data-name="Path 358" d="M16 12.43L4.69 0" stroke="#7a2766"/></g></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'food-cat'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="004-pet-bowl"><path data-name="Path 490" d="M29.92 22l-1.41-5.62h-25L2.08 22H0v1.88h32V22z"/><path data-name="Path 491" d="M26.46 14.46a6 6 0 00-3.54-4.39 6.57 6.57 0 00-2.51-.5 5.26 5.26 0 00-4.68-1.08 7 7 0 00-1.93.76 4.51 4.51 0 00-3.8.61 4.84 4.84 0 00-1.06 1 3.76 3.76 0 00-2.34 1.26 4.51 4.51 0 00-1 2.33z"/></g></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'graph'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path data-name="Path 493" d="M1.64 32h6.64a1.14 1.14 0 001.14-1.14v-9.58a1.14 1.14 0 00-1.14-1.14H1.64A1.14 1.14 0 00.5 21.28v9.57A1.15 1.15 0 001.64 32z"/><path data-name="Path 494" d="M12.56 32h6.64a1.14 1.14 0 001.14-1.14v-15a1.14 1.14 0 00-1.14-1.16h-6.64a1.15 1.15 0 00-1.15 1.14v15A1.15 1.15 0 0012.56 32z"/><path data-name="Path 495" d="M23.47 32h6.65a1.14 1.14 0 001.14-1.14V1.14A1.14 1.14 0 0030.12 0h-6.64a1.14 1.14 0 00-1.14 1.14v29.72A1.14 1.14 0 0023.47 32z"/></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'health'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path data-name="Path 496" d="M26 11.18h-4.18V16H17v4.22h4.85v4.85H26v-4.82h4.85V16H26z"/><path data-name="Path 497" d="M14.86 13.91h4.85V9.07h8.43v4.84h2.44A8.81 8.81 0 0016 4.05 8.81 8.81 0 001.65 14.3c.1.15.22.3.33.45L16 31.69l3.73-4.5v-4.84h-4.87z"/></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'petting-cat'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path data-name="Path 480" d="M9 5.07a.86.86 0 01.86-.86h3.54A3.85 3.85 0 0117 6.77l3.12 1.05a2 2 0 001.48-3.66l-7-3.29a10 10 0 00-9.39.48L1.67 3.48a.86.86 0 00-.41.73v6.86a.86.86 0 00.86.86h2.72a6.69 6.69 0 004.48-1.72h4a2.17 2.17 0 002.18-2.09 2.15 2.15 0 00-2.1-2.19H9.83A.87.87 0 019 5.07z"/><path data-name="001-cat" d="M11.33 22.19c0-.79 0-.62 1.24-2.52A8.75 8.75 0 0117.8 16v-2.22a1.29 1.29 0 011.29-1.29 5.82 5.82 0 015.72 4.75 8.68 8.68 0 013.49 4.84l2 5.17c.5 1.85-13.17 6.67-11.88 3.75a7.37 7.37 0 00.63-2.36H17.8a6.47 6.47 0 01-6.47-6.45z"/></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'play-cat'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="002-domestic-cat-shape"><path data-name="Path 489" d="M2.38 19.47a4.7 4.7 0 00.76 4.27C4 24.88 3 25.54 2.3 25.9s-1 .58-1.06 1.09a7.22 7.22 0 001 2.82 11.37 11.37 0 011.55 1.78.75.75 0 001 .32l.11-.06a.78.78 0 00.92 0 1.09 1.09 0 001.29-.15c.41-.49-.45-1-.45-1-.53-.94-.94-.18-1.09-.39s-.28-.22-1.09-1.16 1.29-.64 2-.82a9.63 9.63 0 002.13-1.12c0 1.12.39.94.94 1.53A4.24 4.24 0 0011.1 30a8.46 8.46 0 011.75.68 4.19 4.19 0 002 .36 2 2 0 001.2 0c1.48 0 .87-.51.33-1.2s-1.6-.1-2.08-.43-1.68-.84-2.29-1.2 0-1 0-1.58a1.67 1.67 0 01.45-1h2.37s1.83-.13 2.31.86 1.22 1.2 1.25 1.73a.52.52 0 01-.44.58c-1-.27-1 1.36-1 1.36 1.25 1.65 3.66-.69 3.66-.69 2.8-.25 2.59-4.37 2.59-4.37a10.1 10.1 0 002-2.43 3 3 0 013.71-1c1.47.71 1.72-.49 1.72-.49.51.08.66-.58.66-.58.67-.74-.5-.91-.53-2.74s-1.55-2.16-1.47-2.7 1.62-2.23 1.24-2.08-1.73.83-2.08 1a1.8 1.8 0 00-.08-.21c-.2-.45-2.44 1-2.44 1a41.73 41.73 0 01-4.23 2.25c-.69.15-.61-.3-1.45-.23s-1.26.15-5-1.45-10.65 1-10.65 1-5.48-8.8-.91-12.57S2.15-3 .66 5.19s2.16 12.5 1.72 14.28z"/></g></svg>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-if="identifier === 'ball-cat'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="003-ball"><path data-name="Path 481" d="M23.07 20.19h-1.41a11.09 11.09 0 002.74-2.64 26.5 26.5 0 01-6.54.15 27.63 27.63 0 00.31 4.36h4.89a1.55 1.55 0 010 3.1H8A3.42 3.42 0 108 32h7.56a.94.94 0 000-1.87H8A1.55 1.55 0 017.84 27h15.23a3.42 3.42 0 000-6.84z"/><path data-name="Path 482" d="M26.55 11a26.36 26.36 0 01-7.78.13 27.06 27.06 0 00-.77 4.69 24.73 24.73 0 007.53-.37h.12a11 11 0 00.9-4.45z"/><path data-name="Path 483" d="M6.91 4.12L8 4.9A24.77 24.77 0 0011.47 7a27.41 27.41 0 013-6.07L15 0a11 11 0 00-8.09 4.12z"/><path data-name="Path 484" d="M22.7 2.66l-.6.93a25.36 25.36 0 00-2.78 5.68 24.57 24.57 0 007.06-.19 11 11 0 00-3.68-6.42z"/><path data-name="Path 485" d="M6.91 6.43l-1-.75a10.94 10.94 0 00-1.35 4.43l1.83 1.31A24.38 24.38 0 0010 13.58c.09-.91.22-1.83.4-2.74q.21-1 .48-2.07a26.29 26.29 0 01-3.97-2.34z"/><path data-name="Path 486" d="M9.91 15.59a26.43 26.43 0 01-4.65-2.65l-.7-.49a11.1 11.1 0 005.68 8.28 29.47 29.47 0 01-.33-5.14z"/><path data-name="Path 487" d="M17.2.12L16 1.92a26.61 26.61 0 00-3.76 19.42v.24a10.9 10.9 0 003.21.48h.76a28.89 28.89 0 01.24-9.55 27.61 27.61 0 014-9.95l.65-1A10.91 10.91 0 0017.2.12z"/></g></svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" v-if="identifier === 'close'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.56 2.56L8.621 6.498l3.939 3.939a1.5 1.5 0 11-2.121 2.122L6.5 8.621 2.56 12.56a1.5 1.5 0 11-2.121-2.121l3.939-3.941L.439 2.56A1.5 1.5 0 112.56.439L6.5 4.378 10.439.439A1.5 1.5 0 0112.56 2.56z"/></svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" v-if="identifier === 'plus'" :class="className" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g transform="translate(-194 -333)" fill="currentColor"><rect width="12" height="2" rx="1" transform="translate(194 338)"/><rect width="12" height="2" rx="1" transform="rotate(90 -66 267)"/></g></svg>
</template>

<script>
export default {
  props: {
    identifier: String,
    className: String,
  },
};
</script>
